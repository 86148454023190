// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"src/components/header/UserButton.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "src/components/header/UserButton.tsx");
  import.meta.hot.lastModified = "1726684194661.5457";
}
// REMIX HMR END

import { Fragment } from 'react';
import { Menu, MenuButton, MenuItems, MenuItem, Transition } from '@headlessui/react';
import { UserIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';
import { Form, Link } from '@remix-run/react';
const loggedInMenuItems = [{
  title: 'My account',
  link: '/account/dashboard'
}, {
  title: 'Log out'
}];
const loggedOutMenuItems = [{
  title: 'Log in',
  link: '/sign-in'
}, {
  title: 'Register',
  link: '/sign-up'
}];
export const UserButton = ({
  onSignOutClick,
  isSignedIn
}) => {
  const menuItems = isSignedIn ? loggedInMenuItems : loggedOutMenuItems;
  return <Menu as="div" className="relative inline-block text-left">
      <MenuButton data-qa="userButton" className="bg-white bg-opacity-20 rounded-full p-2 w-11 h-11 flex justify-center items-center">
        <UserIcon className="mx-1 w-6 h-6 text-white  dark:text-slate-300" />
      </MenuButton>
      <Transition as={Fragment} enter="transition ease-out duration-100" enterFrom="transform opacity-0 scale-95" enterTo="transform opacity-100 scale-100" leave="transition ease-in duration-75" leaveFrom="transform opacity-100 scale-100" leaveTo="transform opacity-0 scale-95">
        <MenuItems className="absolute right-0 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          {menuItems.map(item => {
          return <MenuItem key={item.title}>
                {({
              active
            }) => {
              if (!item.link) {
                return <div className={clsx(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700 dark:text-slate-400 cursor-pointer')}>
                        <Form method="POST" action="/api/logout">
                          <button type="submit" onClick={onSignOutClick}>
                            Log out
                          </button>
                        </Form>
                      </div>;
              }
              return <Link className={clsx(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700 dark:text-slate-400 cursor-pointer')} to={item.link}>
                      {item.title}
                    </Link>;
            }}
              </MenuItem>;
        })}
        </MenuItems>
      </Transition>
    </Menu>;
};
_c = UserButton;
var _c;
$RefreshReg$(_c, "UserButton");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;