// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"src/components/footer/index.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "src/components/footer/index.tsx");
  import.meta.hot.lastModified = "1726684194661.5457";
}
// REMIX HMR END

import React from 'react';
import { Link } from '@remix-run/react';
import { Location } from '../Location';
import { SocialLink } from './SocialLink';
import clsx from 'clsx';
const navigation = {
  support: [{
    name: 'Help',
    href: '#'
  }, {
    name: 'Track order',
    href: '#'
  }, {
    name: 'Shipping',
    href: '#'
  }, {
    name: 'Returns',
    href: '#'
  }],
  company: [{
    name: 'About',
    href: '#'
  }, {
    name: 'Blog',
    href: '#'
  }, {
    name: 'Corporate responsibility',
    href: '#'
  }, {
    name: 'Press',
    href: '#'
  }]
};
const socialLinks = [{
  name: 'facebook',
  icon: '/assets/facebook_icon.svg',
  link: ''
}, {
  name: 'instagram',
  icon: '/assets/instagram_icon.svg',
  link: ''
}, {
  name: 'x',
  icon: '/assets/twitter_icon.svg',
  link: ''
}, {
  name: 'youtube',
  icon: '/assets/youtube_icon.svg',
  link: ''
}, {
  name: 'linkedin',
  icon: '/assets/linkedin_icon.svg',
  link: ''
}, {
  name: 'tiktok',
  icon: '/assets/tiktok_icon.svg',
  link: ''
}];
export default function Footer({
  collections,
  activeChannel,
  locations
}) {
  _s();
  const storeLogo = activeChannel.customFields?.storeLogo?.preview;
  const socials = React.useMemo(() => {
    return socialLinks.map(s => {
      switch (s.name) {
        case 'facebook':
          s.link = activeChannel.customFields.facebookUrl ?? '';
          break;
        case 'x':
          s.link = activeChannel.customFields.xUrl ?? '';
          break;
        case 'instagram':
          s.link = activeChannel.customFields.instagramUrl ?? '';
          break;
        case 'tiktok':
          s.link = activeChannel.customFields.tiktokUrl ?? '';
          break;
        case 'youtube':
          s.link = activeChannel.customFields.youtubeUrl ?? '';
          break;
        case 'linkedin':
          s.link = '';
          break;
      }
      return s;
    }).filter(s => s.link?.length);
  }, [activeChannel]);
  return <footer className={clsx('border-t bg-gray-50', 'dark:bg-slate-900')} aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="flex flex-col justify-start items-center lg:flex-row lg:justify-start lg:items-start">
          <div className="w-full flex flex-col mb-12 justify-center items-center lg:w-auto lg:flex-none lg:flex lg:justify-start lg:mr-24">
            {storeLogo && <img className="rounded-xl flex-grow object-cover w-[250px] lg:grow-0" alt="" src={storeLogo} />}
            <div className="flex justify-center items-center space-x-4">
              {socials.map((social, idx) => <SocialLink social={social} key={`social-${idx}`} />)}
            </div>
          </div>
          <div className="grid grid-cols-2 gap-8 xl:grow">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-bold text-gray-500 tracking-wider uppercase">
                  Shop
                </h3>
                <ul role="list" className="mt-4 space-y-4">
                  {collections.map(collection => <li key={collection.id}>
                      <Link className="text-base text-gray-500 hover:text-gray-600 dark:text-slate-400 dark:hover:text-slate-300" to={'/collections/' + collection.slug} prefetch="intent" key={collection.id}>
                        {collection.name}
                      </Link>
                    </li>)}
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <h3 className="text-sm font-bold text-gray-500 tracking-wider uppercase">
                  Support
                </h3>
                <ul role="list" className="mt-4 space-y-4">
                  {navigation.support.map(item => <li key={item.name}>
                      <a href={item.href} className="text-base text-gray-500 hover:text-gray-600 dark:text-slate-400 dark:hover:text-slate-300">
                        {item.name}
                      </a>
                    </li>)}
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-bold text-gray-500 tracking-wider uppercase">
                  Company
                </h3>
                <ul role="list" className="mt-4 space-y-4">
                  {navigation.company.map(item => <li key={item.name}>
                      <a href={item.href} className="text-base text-gray-500 hover:text-gray-600 dark:text-slate-400 dark:hover:text-slate-300">
                        {item.name}
                      </a>
                    </li>)}
                </ul>
              </div>
              {locations.filter(location => !!location.address).length ? <div className="mt-12 md:mt-0">
                  <h3 className="text-sm font-bold text-gray-500 tracking-wider uppercase">
                    Locations
                  </h3>
                  <ul role="list" className="mt-4 space-y-4">
                    {locations.filter(location => !!location.address).map(location => <li key={location.id}>
                          <Location label={location.label} address={location.address} />
                        </li>)}
                  </ul>
                </div> : null}
            </div>
          </div>
        </div>
      </div>
    </footer>;
}
_s(Footer, "fy5ExymGjo/Po99Q5XTRtEPV2pw=");
_c = Footer;
var _c;
$RefreshReg$(_c, "Footer");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;